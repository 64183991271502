<template>
    <div :style="{ width: lineWidth }">
        <div class="real_run_line">
            <div class="line"></div>
            <div class="site_box">
                <site type="0" v-for="(one, key) in upList" :key="one.siteId" :num="key + 1" :siteName="one.cName" :vehicleList="siteVehMap[one.siteId]"></site>
            </div>
        </div>
        <div class="real_run_line type_down" v-if="rType == '0'">
            <div class="line"></div>
            <div class="site_box">
                <site
                    type="1"
                    v-for="(one, key) in downList"
                    :num="downList.length - key"
                    :key="one.siteId"
                    :siteName="one.cName"
                    :vehicleList="siteVehMap[one.siteId]"
                ></site>
            </div>
        </div>
    </div>
</template>
<script>
    //rType: 0-上下行 1-环形
    //goType: 1:上行 2:下行 3:环形
    import site from "./site.vue";
    export default {
        mounted() {
            this.initSites();
            // this.getRuningVehicle();
        },
        data() {
            return {
                upList: [],
                downList: [],

                // 存放站点下车辆
                siteVehMap: {}
            };
        },
        props: {
            routeId: String,
            rType: String
        },
        components: {
            site
        },
        computed: {
            lineWidth() {
                const max = Math.max(this.upList.length, this.downList.length);
                return max * 30 + 100 + "px";
            }
        },
        methods: {
            init(vehicleId) {
                if (vehicleId) {
                    this.queryVehiclePosition(vehicleId);
                } else {
                    this.getRuningVehicle();
                }
            },
            initSites() {
                let url = "/site/info/queryByRouteId";
                let params = {
                    routeId: this.routeId
                };
                this.$http.post(url, params).then(({ detail }) => {
                    if (detail) {
                        if (this.rType == "1") {
                            this.upList = detail;
                        } else {
                            detail.forEach(site => {
                                if (site.routeSite.goType === "2") {
                                    this.downList.push({
                                        ...site.siteInfo,
                                        ...site.routeSite
                                    });
                                } else {
                                    this.upList.push({
                                        ...site.siteInfo,
                                        ...site.routeSite
                                    });
                                }
                            });
                        }
                    }
                });
            },
            getRuningVehicle() {
                let url = "/waybillActual/queryRealtimeVehicleList";
                let params = {
                    routeId: this.routeId,
                    oDate: new Date().Format("yyyy-MM-dd")
                };
                this.$http.post(url, params).then(({ detail }) => {
                    if (Array.isArray(detail)) {
                        let runingVehicleList = detail;
                        this.queryVehiclePosition(runingVehicleList.map(item => item.vehicleId).join(","));
                    }
                });
            },
            queryVehiclePosition(ids) {
                this.siteVehMap = {};
                if (ids && ids.length) {
                    let url = "/waybillActual/queryVehiclePosition";
                    let params = {
                        vehicleIds: ids
                    };
                    this.$http.post(url, params).then(({ detail }) => {
                        detail.forEach(vehicle => {
                            if (this.siteVehMap[vehicle.siteId]) {
                                const vArrays = this.siteVehMap[vehicle.siteId];
                                vArrays.push(vehicle);
                            } else {
                                this.$set(this.siteVehMap, vehicle.siteId, [vehicle]);
                            }
                        });
                    });
                }
            }
        }
    };
</script>
<style lang="scss" scoped="real_run_line">
    @import "./realTime.scss";
    $dis: 68px;
    $sizedt: $site_size / 2;

    .real_run_line {
        position: relative;
        height: 222px;

        .line {
            height: 3px;
            width: 100%;
            background: #4e95ef;
            position: absolute;
            z-index: -1;
            bottom: $dis;
        }

        .site_box {
            display: flex;
            justify-content: space-between;
        }

        &.type_down {
            .line {
                top: $dis;
            }
        }
    }
</style>
