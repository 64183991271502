<template>
    <el-dialog :visible.sync="visible" title="过站明细">
        <jy-table :data="list">
            <jy-table-column prop="siteNa" label="站点名称" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="aTime" label="进站时间" :formatter="timeFil"></jy-table-column>
            <jy-table-column prop="lTime" label="离站时间" :formatter="timeFil"></jy-table-column>
            <jy-table-column prop="stopTime" label="停留时间(秒)"></jy-table-column>
            <jy-table-column prop="spd" label="进站速度(km/h)"></jy-table-column>
        </jy-table>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            schemeTFil(row, column, cellVal) {
                if (cellVal == "1") {
                    return "上行";
                } else if (cellVal == "2") {
                    return "下行";
                } else {
                    return "环形";
                }
            },
            timeFil(row, column, cellVal) {
                if (cellVal) {
                    return cellVal.split(" ")[1];
                } else {
                    return "";
                }
            },
            init(waybillId) {
                this.visible = true;
                this.$http
                    .post("/waybillActual/waybillDetail", {
                        waybillId
                    })
                    .then(res => {
                        this.list = res.detail;
                    });
            }
        },
        data() {
            return {
                visible: false,

                list: []
            };
        }
    };
</script>
<style></style>
