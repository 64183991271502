<template>
    <div :style="{ width: getWidth, height: getHeight }" class="right-wrapper__car">
        <video-module class="video-module" @expanded="expandedModule"></video-module>
        <vehicle-detail v-show="showDetail"></vehicle-detail>
    </div>
</template>

<script>
    import VideoModule from "./VideoModule.vue";
    import VehicleDetail from "./VehicleDetail.vue";
    export default {
        methods: {
            expandedModule(expanded) {
                this.showDetail = !expanded;
            }
        },
        data() {
            return {
                showDetail: true
            };
        },
        computed: {
            getWidth() {
                if (this.showDetail) {
                    return 470 + "px";
                } else {
                    return "calc(100vw - 360px)";
                }
            },
            getHeight() {
                if (this.showDetail) {
                    return 780 + "px";
                } else {
                    return "calc(100vh - 200px)";
                }
            }
        },
        components: { VehicleDetail, VideoModule }
    };
</script>
<style lang="scss" scoped>
    .right-wrapper__car {
        display: flex;
        flex-direction: column;
        .video-module {
            flex: 1;
        }
    }
</style>
