<template>
    <transition name="shrink">
        <div class="controls-wrapper" :style="{ height: visible ? '150px' : '0px' }">
            <img @click="start" v-if="!run" src="@/assets/monitorMap/start_lushu.svg" />
            <img @click="pause" v-else src="@/assets/monitorMap/pause_lushu.svg" />
            <img @click="stop" src="@/assets/monitorMap/stop_lushu.svg" />
            <slider v-model="progress" :min="0" :max="100" @change="changePro" @mousedown="mousedown"></slider>
            <label>{{ progress }}%</label>
            <el-button type="primary" @click="close">关闭</el-button>
        </div>
    </transition>
</template>

<script>
    import DataStore from "../../DataStore";
    import Slider from "../slider/index.vue";
    export default {
        inject: ["dataStore"],
        mounted() {
            this.dataStore.on(
                "progress",
                val => {
                    this.progress = val;
                },
                "trackControl"
            );
            this.dataStore.on("vehicleId", () => {
                this.close();
            });
        },
        methods: {
            mousedown() {
                this.pause();
            },
            init(clientId) {
                this.visible = true;
                this.clientId = clientId;
            },
            close() {
                this.stop();
                this.progress = 0;
                this.dataStore.progress = 0;
                this.visible = false;
            },
            start() {
                this.run = true;
                this.dataStore.trigger("start", this.clientId);
            },
            pause() {
                this.run = false;
                this.dataStore.trigger("pause");
            },
            stop() {
                this.isStart = false;
                this.dataStore.trigger("stop");
            },
            changePro(val) {
                // 同步拖动
                DataStore.target = "trackControl";
                this.dataStore.progress = val;
                this.dataStore.trigger("progress", val);
            }
        },
        data() {
            return {
                visible: false,
                progress: 0,

                run: false
            };
        },
        components: { Slider }
    };
</script>
<style lang="scss" scoped>
    .controls-wrapper {
        position: fixed;
        width: 700px;
        height: 150px;
        line-height: 150px;
        right: 0;
        bottom: 0;
        background-color: #fff;
        overflow-y: hidden;
        padding-left: 20px;
        z-index: 9;
        img {
            width: 33px;
            height: 33px;
            margin-right: 10px;
            cursor: pointer;
        }
        .el-slider {
            margin-left: 20px;
            display: inline-block;
            width: 400px;
            vertical-align: middle;
        }

        label {
            vertical-align: middle;
            margin-left: 10px;
        }

        .el-button {
            margin-left: 10px;
        }
    }

    .shrink-enter-active,
    .shrink-leave-active {
        transition: height 0.5s;
    }
    .shrink-enter, .shrink-leave-to /* .fade-leave-active below version 2.1.8 */ {
        height: 0;
    }
</style>
