<template>
    <div class="win-wrapper">
        <error-count v-show="moduleName === 'errorCount'"></error-count>
        <route-class v-show="moduleName === 'routeClass'"></route-class>
        <vehicle-detail v-show="moduleName === 'vehicleDetail'"></vehicle-detail>
    </div>
</template>

<script>
    import ErrorCount from "./ErrorCount.vue";
    import RouteClass from "./RouteClass.vue";
    import VehicleDetail from "./vehicleDetail/index.vue";
    export default {
        inject: ["dataStore"],
        mounted() {
            this.dataStore.on("vehicleId", () => {
                this.moduleName = "vehicleDetail";
            });
            this.dataStore.on("routeIds", () => {
                this.moduleName = "routeClass";
            });
        },
        data() {
            return {
                moduleName: "errorCount"
            };
        },
        components: { ErrorCount, RouteClass, VehicleDetail }
    };
</script>
