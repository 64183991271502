<template>
    <el-dialog :visible.sync="visible" title="文本下发" width="450px">
        <el-input type="textarea" :rows="4" placeholder="请输入要下发的文本" v-model="message"></el-input>
        <div slot="footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="send">发送</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            close() {
                this.message = "";
                this.visible = false;
            },
            init(vehicleId) {
                this.visible = true;
                this.vehicleId = vehicleId;
            },
            send() {
                if (!this.message) {
                    this.$message({
                        type: "warning",
                        message: "请输入文本内容"
                    });
                    return false;
                }
                let url = "/waybillPlan/textSend";
                let option = {
                    message: this.message,
                    vehicleId: this.vehicleId
                };
                this.$http.post(url, option).then(() => {
                    this.visible = false;
                    this.$message({
                        type: "success",
                        message: "下发成功!"
                    });
                });
            }
        },
        data() {
            return {
                visible: false,

                message: ""
            };
        }
    };
</script>
<style></style>
