<template>
    <el-dialog :visible.sync="visible" title="司机信息">
        <h3>基本信息</h3>
        <el-row>
            <el-col :span="8">姓名：{{ driverNa }}</el-col>
            <el-col :span="8">司机工号：{{ workNum }}</el-col>
            <el-col :span="8">所属机构：{{ orgNa }}</el-col>
        </el-row>
        <h3>今日排班</h3>
        <jy-table :data="list">
            <jy-table-column label="发车时间" prop="dTime"></jy-table-column>
            <jy-table-column label="车牌号码" prop="vehicleNo"></jy-table-column>
            <jy-table-column label="线路" prop="routeNa"></jy-table-column>
            <jy-table-column label="状态">
                <template v-slot="scope">{{ scope.row.eState | formatEState }}</template>
            </jy-table-column>
        </jy-table>
    </el-dialog>
</template>

<script>
    export default {
        filters: {
            formatEState(val) {
                if (val == "2") {
                    return "已完成";
                } else if (val == "1") {
                    return "已发车";
                } else {
                    return "未发车";
                }
            }
        },
        methods: {
            init(vehicleNo, driverNa) {
                this.visible = true;
                this.$http
                    .post("/base/driver/queryPage", {
                        cName: driverNa,
                        pageIndex: 1,
                        pageSize: 1
                    })
                    .then(res => {
                        const { driverInfo, sysOrg } = res.detail.list[0];
                        this.driverNa = driverInfo.cName;
                        this.orgNa = sysOrg.orgNa;
                        this.workNum = driverInfo.wNum;
                    });
                this.$http
                    .post("/waybillPlan/queryPage", {
                        driverNa,
                        vehicleNo,
                        oDate: new Date().Format("yyyy-MM-dd")
                    })
                    .then(res => {
                        this.list = res.detail;
                    });
            }
        },
        data() {
            return {
                visible: false,

                list: [],

                driverNa: "",
                workNum: "",
                orgNa: ""
            };
        }
    };
</script>
<style scoped>
    .el-row {
        margin: 20px 0;
    }
</style>
