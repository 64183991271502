<template>
    <el-dialog :visible.sync="visible" title="今日班次里程完成情况">
        <jy-table :data="list">
            <jy-table-column label="路单编号" prop="waybillId"></jy-table-column>
            <jy-table-column label="线路" prop="routeNa"></jy-table-column>
            <jy-table-column label="驾驶员" prop="driverNa"></jy-table-column>
            <jy-table-column label="起点站" prop="sSiteNa"></jy-table-column>
            <jy-table-column label="终点站" prop="eSiteNa"></jy-table-column>
            <jy-table-column label="开始时间" prop="dTime"></jy-table-column>
            <jy-table-column label="结束时间" prop="oTime"></jy-table-column>
            <jy-table-column label="里程km" prop="runM"></jy-table-column>
            <jy-table-column label="运行情况" prop="eState">
                <template v-slot="scope">{{ scope.row.eState | formatEState }}</template>
            </jy-table-column>
        </jy-table>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            init(vehicleNo) {
                this.visible = true;
                this.$http
                    .post("/waybillActual/queryPage", {
                        vehicleNo,
                        oDate: new Date().Format("yyyy-MM-dd"),
                        pageIndex: 1,
                        pageSize: 10000
                    })
                    .then(res => {
                        this.list = res.detail.list;
                    });
            }
        },
        filters: {
            formatEState(val) {
                if (val == "2") {
                    return "已完成";
                } else if (val == "1") {
                    return "已发车";
                } else {
                    return "未发车";
                }
            }
        },
        data() {
            return {
                visible: false,

                list: []
            };
        }
    };
</script>
<style></style>
