<template>
    <el-dialog :visible.sync="visible" title="当前车辆排班计划">
        <jy-table :data="list">
            <jy-table-column label="线路" prop="routeNa"></jy-table-column>
            <jy-table-column label="车牌号" prop="vehicleNo"></jy-table-column>
            <jy-table-column label="班次序号" prop="type"></jy-table-column>
            <jy-table-column label="发车时间" prop="dTime"></jy-table-column>
            <jy-table-column label="驾驶员" prop="driverNa"></jy-table-column>
            <jy-table-column label="执行状态">
                <template v-slot="scope">{{ scope.row.eState | formatEState }}</template>
            </jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <div slot="footer">
            <el-button @click="close">关闭</el-button>
            <el-button type="primary" @click="addUpdate()">新增班次</el-button>
        </div>

        <add-shift ref="shift" append-to-body></add-shift>
    </el-dialog>
</template>

<script>
    import addShift from "@/components/pages/admin/scheduling/plannedShift/addShift";
    export default {
        filters: {
            formatEState(val) {
                if (val == "2") {
                    return "已完成";
                } else if (val == "1") {
                    return "已发车";
                } else {
                    return "未发车";
                }
            }
        },
        methods: {
            init(vehicleNo, routeId) {
                this.visible = true;
                this.$http
                    .post("/waybillPlan/queryPage", {
                        vehicleNo,
                        oDate: new Date().Format("yyyy-MM-dd")
                    })
                    .then(res => {
                        this.list = res.detail;
                    });
                this.$http
                    .post("/route/info/queryRouteInfoByOrgIds", {
                        routeIds: routeId
                    })
                    .then(res => {
                        this.routeObj = res.detail[0] || {};
                    });
            },
            addUpdate(option) {
                if (option) {
                    this.$refs.shift.init(option, "update");
                } else {
                    let options = {
                        routeId: this.routeObj.routeId,
                        routeNa: this.routeObj.cName,
                        orgId: this.routeObj.orgId,
                        orgPath: this.routeObj.orgPath,
                        oDate: new Date().Format("yyyy-MM-dd")
                    };
                    this.$refs.shift.init(options, "add");
                }
            },
            close() {
                this.visible = false;
            }
        },
        data() {
            return {
                visible: false,

                list: [],

                operateList: [
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.addUpdate,
                        isShow: row => {
                            return row.eState == "0";
                        }
                    }
                ]
            };
        },
        components: {
            // AddClass
            addShift
        }
    };
</script>
<style></style>
