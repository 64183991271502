<template>
    <div class="list-wrapper">
        <el-input v-model="queryStr" suffix-icon="el-icon-search" placeholder="车牌号"></el-input>
        <div class="top_box">
            <div class="item_mode" @click="change('')">
                <img :src="topBoxImg('')" />
                <div class="font_s" :class="topBoxFont('')">全部</div>
                <div class="font_n" :class="topBoxFont('')">({{ total }})</div>
            </div>
            <div class="item_mode" @click="change(1)">
                <img :src="topBoxImg(1)" />
                <div class="font_s" :class="topBoxFont(1)">在线</div>
                <div class="font_n" :class="topBoxFont(1)">({{ onTotal }})</div>
            </div>
            <div class="item_mode" @click="change(0)">
                <img :src="topBoxImg(0)" />
                <div class="font_s" :class="topBoxFont(0)">离线</div>
                <div class="font_n" :class="topBoxFont(0)">({{ offTotal }})</div>
            </div>
        </div>
        <div class="vehicle-list">
            <table>
                <tbody>
                    <tr v-for="item in rlist" :key="item.vhId" @click="focusVehicle(item)">
                        <td><input :checked="checkedId === item.vhId" type="radio" name="vehicle" @click.stop="inputClick" @change="changeSelect(item)" /></td>
                        <td>{{ item.vhNo }}</td>
                        <td :style="{ color: item.onlineNum === '1' ? '#31CAA9' : '#FF3F3F' }">{{ item.onlineNum === "1" ? "在线" : "离线" }}</td>
                        <td><img @click.right.stop="clickRight" src="@/assets/monitorMap/setting.svg" /></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <div class="tooltip" ref="rightContext" aria-hidden="true">
            <el-button>视频监控</el-button>
            <el-button>文本下发</el-button>
            <el-button>轨迹回放</el-button>
            <el-button>语音对讲</el-button>
            <el-button>调整排班</el-button>
            <el-button>执行进度</el-button>
        </div> -->
    </div>
</template>

<script>
    import { createPopper } from "@popperjs/core";
    import all_normal from "@/assets/monitorMap/all-normal.svg";
    import all_selected from "@/assets/monitorMap/all-selected.svg";
    import online_normal from "@/assets/monitorMap/online-normal.svg";
    import online_selected from "@/assets/monitorMap/online-selected.svg";
    import offline_normal from "@/assets/monitorMap/offline-normal.svg";
    import offline_selected from "@/assets/monitorMap/offline-selected.svg";
    export default {
        inject: ["dataStore"],
        methods: {
            focusVehicle(item) {
                this.dataStore.trigger("findCar", item.vhId);
            },
            changeSelect(item) {
                this.dataStore.vehicleId = item.vhId;
                this.dataStore.trigger("vehicleId", item.vhId);
            },
            load() {
                this.$http
                    .post("/realtime/queryVehicleEqual", {
                        orgIds: this.dataStore.routeIds,
                        onlineState: this.activeBtn,
                        vhNo: this.queryStr
                    })
                    .then(res => {
                        this.list = res.detail;
                        this.total = this.list.length;
                        this.onTotal = this.list.filter(v => v.onlineNum === "1").length;
                    });
            },
            reset() {
                this.list = [];
                this.total = 0;
                this.onTotal = 0;
                this.activeBtn = "";
                this.dataStore.vehicleId = "";
                this.checkedId = "";
            },
            change(val) {
                this.activeBtn = val;
            },
            topBoxFont(type) {
                return this.activeBtn === type ? "font_active" : "font_normal";
            },
            topBoxImg(type) {
                switch (type) {
                    case "":
                        if (this.activeBtn === type) {
                            return all_selected;
                        } else {
                            return all_normal;
                        }
                    case 1:
                        if (this.activeBtn === type) {
                            return online_selected;
                        } else {
                            return online_normal;
                        }
                    case 0:
                        if (this.activeBtn === type) {
                            return offline_selected;
                        } else {
                            return offline_normal;
                        }
                }
            },
            changeRadio(vehicleId) {
                this.checkedId = vehicleId;
                this.dataStore.vehicleId = vehicleId;
                this.dataStore.trigger("vehicleId", vehicleId);
            },
            clickRight(event) {
                event.preventDefault();
                this.createDialog(event.target);
            },
            createDialog(target) {
                const tooltip = this.$refs.rightContext;
                this.popperVM = createPopper(target, tooltip, {
                    placement: "right-start"
                });
            },
            inputClick() {}
        },
        mounted() {
            this.dataStore.on("routeIds", () => {
                this.reset();
                if (this.dataStore.routeIds.length) {
                    this.load();
                }
            });
        },
        data() {
            return {
                activeBtn: "",
                queryStr: "",

                list: [],

                total: 0,
                onTotal: 0,

                checkedId: ""
            };
        },
        computed: {
            offTotal() {
                const gap = this.total - this.onTotal;
                return Math.max(gap, 0);
            },
            rlist() {
                if (this.activeBtn === 1) {
                    return this.list.filter(v => v.onlineNum == 1 && v.vhNo.indexOf(this.queryStr) !== -1);
                } else if (this.activeBtn === 0) {
                    return this.list.filter(v => v.onlineNum == 0 && v.vhNo.indexOf(this.queryStr) !== -1);
                } else {
                    return this.list.filter(v => v.vhNo.indexOf(this.queryStr) !== -1);
                }
            }
        }
    };
</script>
<style lang="scss" scoped>
    .list-wrapper {
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        .el-input {
            margin: 13px 0;
        }
        .top_box {
            display: flex;
            justify-content: space-around;
            background-color: white;
            padding: 5px;
            flex-shrink: 0;

            .item_mode {
                flex: 1;
                text-align: center;
                cursor: pointer;
                margin: 5px 0;

                .font_s,
                .font_n {
                    text-align: center;
                    font-weight: 600;

                    &.font_normal {
                        color: #333333;
                    }

                    &.font_active {
                        color: #4178bd;
                    }
                }

                .font_s {
                    font-size: 12px;
                }

                .font_n {
                    font-size: 14px;
                }

                img {
                    height: 24px;
                    width: 24px;
                }
            }
        }

        .vehicle-list {
            flex: 1;
            overflow-y: auto;
            table {
                width: 100%;
                tr {
                    cursor: pointer;
                    td {
                        padding: 5px;
                        input[type="radio"] {
                            cursor: pointer;
                        }
                        img {
                            cursor: pointer;
                        }
                    }
                }

                tr:nth-child(even) {
                    background-color: #dfe6e9;
                }

                tr:hover {
                    background-color: #b2bec3;
                }
            }
        }
    }

    .tooltip {
        display: inline-block;
        background: #ffffff;
        color: #643045;
        font-weight: bold;
        padding: 5px 10px;
        font-size: 13px;
        border-radius: 4px;
    }

    .tooltip[aria-hidden="true"] {
        visibility: hidden;
        pointer-events: none;
        & > .arrow {
            visibility: hidden;
        }
    }

    .tooltip[aria-hidden="false"] {
        visibility: visible;
        pointer-events: visible;
        & > .arrow {
            visibility: visible;
        }
    }

    .arrow::before {
        content: "";
        transform: rotate(45deg);
        position: absolute;
        width: 8px;
        height: 8px;
        top: -8px;
        left: -4px;
        background: #ffffff;
    }

    .tooltip[data-popper-placement^="top"] > .arrow {
        bottom: -4px;
    }

    .tooltip[data-popper-placement^="bottom"] > .arrow {
        top: -4px;
    }

    .tooltip[data-popper-placement^="left"] > .arrow {
        right: -4px;
    }

    .tooltip[data-popper-placement^="right"] > .arrow {
        left: -4px;
    }
</style>
