<template>
    <div class="win-wrapper">
        <div class="win-header">
            <div class="header-tabs">
                <div :class="{ selected: active === 'dataMap' }" class="header-tab__label header-tab__label--left" @click="change('dataMap')">地图模式</div>
                <div :class="{ selected: active === 'modalCom' }" class="header-tab__label header-tab__label--right" @click="change('modalCom')">模拟视图</div>
            </div>
        </div>
        <div class="win-body">
            <div class="module-title">
                <span>线路列表</span>
                <span>移交调度</span>
            </div>

            <tree-com class="organization-tree"></tree-com>

            <div class="module-title vehicle-com">车辆列表</div>
            <vehicle-list></vehicle-list>
        </div>
    </div>
</template>

<script>
    import TreeCom from "./TreeCom.vue";
    import VehicleList from "./VehicleList.vue";
    export default {
        methods: {
            change(comp) {
                this.active = comp;
                const parent = this.$parent;
                parent.moduleName = comp;
            }
        },
        data() {
            return {
                active: "dataMap"
            };
        },
        components: { TreeCom, VehicleList }
    };
</script>
<style lang="scss" scoped>
    .win-wrapper {
        height: 100%;
        .win-header {
            height: 40px;
            margin-bottom: 10px;
            .header-tabs {
                font-size: 18px;
                line-height: 40px;
                text-align: center;
                background-color: #ffffff;
                .header-tab__label {
                    display: inline-block;
                    width: 50%;
                    cursor: pointer;
                }
                .header-tab__label--left {
                    border-radius: 5px 0px 0px 5px;
                }
                .header-tab__label--right {
                    border-radius: 0px 5px 5px 0px;
                }
                .selected {
                    background-color: #4786e3;
                    color: #ffffff;
                }
            }
        }
        .win-body {
            background-color: #ffffff;
            border-radius: 5px;
            padding: 20px 15px;
            .organization-tree {
                margin-top: 25px;
            }
            .vehicle-com {
                margin-top: 40px;
            }
        }
    }
</style>
