<template>
    <div class="el-slider" role="slider" :aria-valuemin="min" :aria-valuemax="max" aria-orientation="horizontal">
        <div class="el-slider__runway" @click="onSliderClick">
            <div class="el-slider__bar" ref="slider"></div>
            <slider-button v-on="$listeners" v-model="firstValue" ref="button1"></slider-button>
        </div>
    </div>
</template>

<script type="text/babel">
    import SliderButton from "./button.vue";
    export default {
        name: "Slider",

        props: {
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 100
            },
            value: {
                type: [Number, Array],
                default: 0
            },
            debounce: {
                type: Number,
                default: 300
            },
            label: {
                type: String
            }
        },

        components: {
            SliderButton
        },

        data() {
            return {
                firstValue: null,
                oldValue: null,
                dragging: false,
                sliderSize: 1
            };
        },

        watch: {
            value(val, oldVal) {
                if (this.dragging || (Array.isArray(val) && Array.isArray(oldVal) && val.every((item, index) => item === oldVal[index]))) {
                    return;
                }
                this.setValues();
            },

            dragging(val) {
                if (!val) {
                    this.setValues();
                }
            },

            firstValue(val) {
                this.$emit("input", val);
            },

            min() {
                this.setValues();
            },

            max() {
                this.setValues();
            }
        },

        methods: {
            valueChanged() {
                return this.value !== this.oldValue;
            },
            setValues() {
                if (this.min > this.max) {
                    console.error("[Element Error][Slider]min should not be greater than max.");
                    return;
                }
                const val = this.value;
                if (typeof val === "number" && !isNaN(val)) {
                    if (val < this.min) {
                        this.$emit("input", this.min);
                    } else if (val > this.max) {
                        this.$emit("input", this.max);
                    } else {
                        this.firstValue = val;
                        if (this.valueChanged()) {
                            this.oldValue = val;
                        }
                    }
                }
            },

            setPosition(percent) {
                this.$refs.button1.setPosition(percent);
            },

            onSliderClick(event) {
                if (this.dragging) {
                    return;
                }
                this.resetSize();
                const sliderOffsetLeft = this.$refs.slider.getBoundingClientRect().left;
                this.setPosition(((event.clientX - sliderOffsetLeft) / this.sliderSize) * 100);
                this.emitChange();
            },

            resetSize() {
                if (this.$refs.slider) {
                    this.sliderSize = this.$refs.slider[`clientWidth`];
                }
            },

            emitChange() {
                this.$nextTick(() => {
                    this.$emit("change", this.value);
                });
            }
        },

        mounted() {
            let valuetext;

            this.firstValue = Math.min(this.max, Math.max(this.min, this.value));
            this.oldValue = this.firstValue;
            valuetext = this.firstValue;
            this.$el.setAttribute("aria-valuetext", valuetext);

            // label screen reader
            this.$el.setAttribute("aria-label", this.label ? this.label : `slider between ${this.min} and ${this.max}`);

            this.resetSize();
            window.addEventListener("resize", this.resetSize);
        },

        beforeDestroy() {
            window.removeEventListener("resize", this.resetSize);
        }
    };
</script>
<style lang="scss" scoped>
    .slider__disabled {
        position: absolute;
        top: 0;
    }
    .el-slider__bar {
        width: 100%;
    }
    .el-slider__runway {
        margin: 8px 0;
        .el-slider__bar {
            background-color: #3879ff;
        }
    }
</style>
