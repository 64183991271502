<template>
    <div class="error-count">
        <div class="module-title">异常统计</div>
        <div class="data-item">
            <img src="@/assets/monitorMap/big-interval.png" />
            <span>
                <span>大间隔</span>
                <br />
                <span class="text-value">{{ formError.largeIntervalTimes }}</span>
            </span>
        </div>
        <div class="data-item">
            <img src="@/assets/monitorMap/serial-car.png" />
            <span>
                <span>串车</span>
                <br />
                <span class="text-value">{{ formError.stringCarTimes }}</span>
            </span>
        </div>
        <div class="data-item">
            <img src="@/assets/monitorMap/offtrack.png" />
            <span>
                <span>偏离线路</span>
                <br />
                <span class="text-value">{{ formError.dFromLineTimes }}</span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                formError: {
                    largeIntervalTimes: "",
                    stringCarTimes: "",
                    dFromLineTimes: "10"
                }
            };
        },
        created() {
            this.getList();
        },
        methods: {
            getList() {
                let url = "/home/getException";
                this.$http.post(url).then(({ detail }) => {
                    this.formError = detail;
                });
            }
        }
    };
</script>
<style lang="scss" scoped>
    .error-count {
        padding: 10px;
        width: 180px;
        background-color: #fff;
        .data-item {
            font-size: 14px;
            color: #3e71af;
            img {
                margin-right: 10px;
            }
            & > span {
                display: inline-block;
                vertical-align: middle;
                margin: 10px 0;
                .text-value {
                    font-weight: bold;
                }
            }
        }
    }
</style>
