<template>
    <el-dialog :visible.sync="visible" title="当前班次进度">
        <div class="line-wrapper">
            <site-line ref="lineRef" :routeId="routeId" :rType="rType"></site-line>
        </div>
    </el-dialog>
</template>

<script>
    /**
     * 过站明细
     */
    import SiteLine from "../../siteLine/line.vue";
    export default {
        methods: {
            init(routeId, rType, vehicleId) {
                this.routeId = routeId;
                this.rType = rType;
                this.visible = true;
                this.$nextTick(() => {
                    this.$refs.lineRef.init(vehicleId);
                });
            }
        },
        data() {
            return {
                visible: false,
                routeId: "",
                rType: ""
            };
        },
        components: { SiteLine }
    };
</script>
<style lang="scss" scoped>
    .line-wrapper {
        width: 100%;
        overflow-x: auto;
    }
</style>
