<template>
    <el-dialog :visible.sync="visible" title="场站信息">
        <h3>当前选择场站详细信息</h3>
        <el-row>
            <el-col :span="6">场站名称：{{ stationData.cName }}</el-col>
            <el-col :span="6">场站类型：{{ stationData.sType | formatType }}</el-col>
            <el-col :span="6">场站规模：{{ stationData.type | formatScaleType }}</el-col>
        </el-row>
        <el-row>
            <el-col :span="18">
                <h3>停靠线路发车计划及执行情况</h3>
                <div class="order-list-wrapper">
                    <el-tabs v-model="active">
                        <el-tab-pane v-for="r in routeClassList" :key="r.id" :label="r.name" :name="r.id">
                            <div class="list-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>排班车辆</td>
                                            <td>车辆状态</td>
                                            <td>预发时间</td>
                                            <td>实发时间</td>
                                            <td>实际到达</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="l in r.list" :key="l.waybillId">
                                            <td>{{ l.vehicleNo }}</td>
                                            <td>{{ l.eState | formatEState }}</td>
                                            <td>{{ l.dTime }}</td>
                                            <td>{{ l.actualDtime }}</td>
                                            <td>{{ l.actualOtime }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3>{{ r.name }}线路执行情况</h3>
                            <el-row>
                                <el-col :span="8">计划趟次：{{ r.shifitTotal }}次</el-col>
                                <el-col :span="8">已完成趟次：{{ r.shiftCompletion }}次</el-col>
                                <el-col :span="8">未完成趟次：{{ r.nShiftCompletion }}次</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">完成率：{{ r.shiftCompletionRate * 100 + "%" }}</el-col>
                                <el-col :span="8">到准趟次：{{ r.dePunctuality }}次</el-col>
                                <el-col :span="8">发准率：{{ r.dePunctualityRate * 100 + "%" }}</el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
            <el-col :span="6">
                <h3>停靠车辆：10辆</h3>
                <div class="list-container">
                    <table class="no-tab-header">
                        <thead>
                            <tr>
                                <td>线路</td>
                                <td>车牌号</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="v in stopList" :key="v.vhId">
                                <td>{{ v.orgNa }}</td>
                                <td>{{ v.vhNo }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
    import { getStationTypeList, getScaleTypeList } from "@/common/js/wordbook.js";
    export default {
        filters: {
            formatType(val) {
                const list = getStationTypeList();
                const t = list.find(v => v.value == val);
                return t ? t.text : "";
            },
            formatScaleType(val) {
                const list = getScaleTypeList();
                const t = list.find(v => v.value == val);
                return t ? t.text : "";
            },
            formatEState(val) {
                if (val == "2") {
                    return "已完成";
                } else if (val == "1") {
                    return "已发车";
                } else {
                    return "未发车";
                }
            }
        },
        methods: {
            init({ stationId }) {
                this.visible = true;
                this.$http
                    .post("/station/queryStationInfo", {
                        stationId
                    })
                    .then(res => {
                        const { stationInfo, stationRoutes } = res.detail;
                        this.stationData = stationInfo;
                        this.$set(this.stationData, "routeNas", stationRoutes.map(v => v.cName).join(","));
                        if (stationRoutes) {
                            this.routeClassList = [];
                            stationRoutes.forEach(r => {
                                this.getClassByStationId(stationId, r.routeId, r.cName);
                            });
                            this.active = stationRoutes[0].routeId;
                        }
                    });
                this.initStop(stationId);
            },
            initStop(stationId) {
                this.$http.post("/realtime/getVehicleByStationId", { stationId }).then(res => {
                    this.stopList = res.detail;
                });
            },
            async getClassByStationId(stationId, routeId, routeNa) {
                const classes = await this.$http.post("/waybillPlan/queryByStationId", {
                    stationId,
                    routeId
                });
                const statistics = await this.$http.post("/home/getRouteShift", { routeId });
                this.routeClassList.push({
                    name: routeNa,
                    id: routeId,
                    list: classes.detail,
                    ...statistics.detail
                });
            }
        },
        data() {
            return {
                visible: false,
                active: "",
                stationData: {
                    cName: "",
                    sType: "",
                    type: ""
                },
                routeClassList: [],
                stopList: []
            };
        }
    };
</script>
<style lang="scss" scoped>
    .info {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .el-row {
        margin: 10px 0;
    }
    .list-container {
        max-height: 400px;
        overflow-y: auto;
        table {
            width: 100%;
            text-align: center;
        }
        .no-tab-header {
            margin-top: 50px;
        }
    }
</style>
