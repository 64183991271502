export default class DataStore {
    constructor() {
        this.callbackMap = {};

        // 所有页面共用的数据
        // 轨迹回放进度
        this.progress = 0;
        // 选择线路
        this.routeIds = [];
        // 选择车辆
        this.vehicleId = "";
    }
    trigger(property, val) {
        if (this.callbackMap[property]) {
            const callbacks = this.callbackMap[property];
            if (DataStore.target) {
                const target = DataStore.target;
                callbacks.forEach(cbVal => {
                    if (cbVal.target !== target) {
                        cbVal.cb.call(this, val);
                    }
                });
                DataStore.target = "";
            } else {
                callbacks.forEach(cbVal => cbVal.cb.call(this, val));
            }
        }
    }
    /**
     * 监听属性，并调用注册的回调函数
     * @param {*} property 属性
     * @param {*} cb 注册的回调函数
     * @param {*} val 初始值
     * @param {*} target 当前页面的名称，防止当前页面注册的回调函数被调用
     */
    on(property, cb, target = "") {
        const value = {
            cb,
            target
        };

        if (this.callbackMap[property]) {
            const callbacks = this.callbackMap[property];
            callbacks.push(value);
        } else {
            this.callbackMap[property] = [value];
        }
    }
}
