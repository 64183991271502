<template>
    <div class="detail-panel">
        <div class="module-title panel-top">当前选择车辆详细信息</div>
        <div class="panel-mid">
            <div class="vehicle-plate">
                <div class="plate-header">
                    <span>{{ vehicleInfo.orgNa }} {{ vehicleInfo.vehicleNo }}</span>
                    <span>
                        <span>签到时间：{{ (classInfo.signInT || classInfo.sTime) | formatTime }}</span>
                        <!-- <span>预计回厂：{{ (classInfo.pBackT || classInfo.eTime) | formatTime }}</span> -->
                    </span>
                </div>
                <div class="plate-content">
                    <div class="plate-class">
                        <span class="class-site">
                            <span>{{ classInfo.dTime }}</span>
                            <br />
                            <span>{{ classInfo.sSiteNa }}</span>
                        </span>
                        <span>
                            <span>{{ classInfo.schemeT == 1 ? "上行" : "下行" }}</span>
                            <br />
                            <img src="@/assets/monitorMap/left-arrow.png" />
                        </span>
                        <span class="class-site">
                            <span>{{ classInfo.oTime | formatTime }}</span>
                            <br />
                            <span>{{ classInfo.eSiteNa }}</span>
                        </span>
                    </div>
                    <el-row class="plate-progress">
                        <div class="slider-container cursor" @click="clickSlider">
                            <div class="progress" :style="{ width: Math.floor((classInfo.sRunMil / classInfo.sTotalMil) * 100) + '%' }">
                                {{ Math.floor((classInfo.sRunMil / classInfo.sTotalMil) * 100) }}%
                            </div>
                        </div>
                        <span class="slider-label">{{ classInfo.sRunMil }}km/{{ classInfo.sTotalMil }}km</span>
                        <img class="cursor slider-button" src="@/assets/monitorMap/u1505.png" @click="clickPass" />
                    </el-row>
                    <el-row class="plate-site">
                        <el-col class="text-hidden" :span="8">
                            <span>上一站：</span>
                            <br />
                            <span>{{ classInfo.pSiteNa }}</span>
                        </el-col>
                        <el-col :span="8" class="text-hidden blueText">
                            <span>当前站：</span>
                            <br />
                            <span>{{ classInfo.siteNa }}</span>
                        </el-col>
                        <el-col class="text-hidden" :span="8">
                            <span>下一站：</span>
                            <br />
                            <span>{{ classInfo.nSiteNa }}</span>
                        </el-col>
                    </el-row>
                    <div class="vehicle-controls">
                        <el-button @click="sendText">文本下发</el-button>
                        <el-button @click="trackReplay">轨迹回放</el-button>
                        <!-- <el-button>语音对讲</el-button> -->
                        <el-button @click="clickAjust">调整排班</el-button>
                        <!-- <el-button @click="clickHandover">移交调度</el-button> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-bottom">
            <div class="vehicle-mileage">
                <div>
                    <span>今日里程：</span>
                    <span class="cursor bigText blueText" @click="clickService">{{ vehicleInfo.todayMil }}km</span>
                </div>
                <div ref="speed" class="speed-chart"></div>
                <div class="data-label">当前时速 {{ Math.floor(vehicleInfo.speed) }}km/h</div>
            </div>
            <div class="vehicle-times">
                <div>
                    <span>已完成趟次：</span>
                    <span class="cursor bigText blueText" @click="clickService">{{ vehicleInfo.fShifts }}次</span>
                </div>
                <img class="driver-logo" :src="driverDefault" />
                <div class="data-label">
                    驾驶员：{{ driverInfo.driverNa }}
                    <img class="cursor driver-entry" src="@/assets/monitorMap/u1501.png" @click="clickDriver" />
                </div>
            </div>
        </div>

        <pass-detail ref="pass"></pass-detail>
        <current-class ref="current"></current-class>
        <class-complete ref="complete"></class-complete>
        <driver-detail ref="driver"></driver-detail>
        <class-plan ref="ajust"></class-plan>
        <text-send ref="send"></text-send>
        <track-control ref="track"></track-control>
    </div>
</template>

<script>
    import driverDefault from "@/assets/serviceMap/rightAside/driver_background.png";
    import PassDetail from "./PassDetail.vue";
    import CurrentClass from "./CurrentClass.vue";
    import ClassComplete from "./ClassComplete.vue";
    import DriverDetail from "./DriverDetail.vue";
    import ClassPlan from "./ClassPlan.vue";
    import TextSend from "./TextSend.vue";
    import TrackControl from "./TrackControl.vue";

    import * as Echarts from "echarts";
    export default {
        inject: ["dataStore"],
        mounted() {
            this.dataStore.on("vehicleId", () => {
                this.init();
            });
        },
        filters: {
            formatTime(val) {
                if (val) {
                    if (val.length === 16) {
                        return val.split(" ")[1];
                    } else {
                        return val;
                    }
                } else {
                    return "-";
                }
            }
        },
        methods: {
            trackReplay() {
                this.$refs.track.init(this.vehicleInfo.clientId);
            },
            sendText() {
                this.$refs.send.init(this.vehicleInfo.vehicleId);
            },
            init() {
                this.$http.post("/base/vehicle/queryVehicleRTInfo", [this.dataStore.vehicleId]).then(res => {
                    if (res.detail) {
                        const detail = res.detail[0];
                        const waybillRTInfo = detail.waybillRTInfo;
                        this.vehicleInfo.vehicleNo = detail.vehicleNo;
                        this.vehicleInfo.orgNa = waybillRTInfo.routeNa;
                        this.vehicleInfo.speed = detail.spd;
                        this.vehicleInfo.fShifts = detail.fShifts;
                        this.vehicleInfo.todayMil = detail.todayMil;
                        this.vehicleInfo.vehicleId = detail.vehicleId;
                        this.vehicleInfo.clientId = detail.clientId;

                        this.classInfo = waybillRTInfo;
                        this.driverInfo = {
                            driverNa: waybillRTInfo.driverNa,
                            driverId: waybillRTInfo.driverId
                        };

                        this.$nextTick(() => {
                            this.drawSpeed(detail.spd);
                        });
                    }
                });
            },
            clickAjust() {
                this.$refs.ajust.init(this.vehicleInfo.vehicleNo, this.classInfo.routeId);
            },
            clickHandover() {
                this.$refs.handover.init();
            },
            clickSlider() {
                this.$http
                    .post("/route/info/queryRouteInfoByOrgIds", {
                        routeIds: this.classInfo.routeId
                    })
                    .then(res => {
                        const [{ routeId, rType }] = res.detail;
                        this.$refs.current.init(routeId, rType, this.vehicleInfo.vehicleId);
                    });
            },
            clickPass() {
                this.$refs.pass.init(this.classInfo.waybillId);
            },
            clickService() {
                this.$refs.complete.init(this.vehicleInfo.vehicleNo);
            },
            clickDriver() {
                this.$refs.driver.init(this.vehicleInfo.vehicleNo, this.driverInfo.driverNa);
            },
            drawSpeed(spd) {
                const dom = this.$refs.speed;
                const chart = Echarts.init(dom);
                const option = {
                    series: [
                        {
                            type: "gauge",
                            min: 0,
                            max: 200,
                            splitNumber: 10,
                            radius: "75%",
                            center: ["50%", "60%"],
                            axisLine: {
                                lineStyle: {
                                    width: 2,
                                    color: [
                                        [0.2, "green"], // 0~10% 红轴
                                        [0.4, "orange"], // 10~20% 绿轴
                                        [1, "red"]
                                    ]
                                }
                            },
                            splitLine: {
                                length: 5,
                                lineStyle: {
                                    width: 1
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                distance: -35,
                                color: "#f00"
                            },
                            pointer: {
                                offsetCenter: [0, "10%"],
                                icon:
                                    "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                                length: "115%",
                                itemStyle: {
                                    color: "#000"
                                }
                            },
                            detail: {
                                valueAnimation: true,
                                precision: 1,
                                offsetCenter: [0, "90%"]
                            },
                            title: {
                                offsetCenter: [0, "-40%"]
                            },
                            data: [
                                {
                                    value: Math.floor(spd),
                                    name: "km/h"
                                }
                            ]
                        }
                    ]
                };
                chart.setOption(option);
            }
        },
        data() {
            return {
                driverDefault,

                // 车辆信息
                vehicleInfo: {},
                // 班次和路单信息
                classInfo: {},
                driverInfo: {}
            };
        },
        components: {
            PassDetail,
            CurrentClass,
            ClassComplete,
            DriverDetail,
            // HandoverDispatch,
            ClassPlan,
            TextSend,
            TrackControl
        }
    };
</script>
<style lang="scss" scoped>
    .text-hidden {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .cursor {
        cursor: pointer;
    }
    .blueText {
        font-weight: bold;
        color: #02a7f0;
    }
    .bigText {
        font-size: 21px;
    }
    .detail-panel {
        background-color: #fff;
        padding: 10px;
        .panel-mid {
            height: 300px;
            margin-top: 10px;
            .vehicle-plate {
                border: 1px solid #dcdcdc;
                .plate-header {
                    background-color: #e8f3fd;
                    color: #4786e3;
                    height: 35px;
                    line-height: 35px;
                    padding: 0 21px;
                    font-size: 16px;
                    font-weight: bold;
                    span:nth-child(1) {
                        float: left;
                    }
                    span:nth-child(2) {
                        float: right;
                    }
                }
                .plate-content {
                    height: 265px;
                    padding: 0 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    .plate-class {
                        display: flex;
                        justify-content: space-around;
                        text-align: center;
                        color: #4786e3;
                        .class-site {
                            font-size: 18px;
                            font-weight: bold;
                        }
                    }
                    .plate-site {
                        text-align: center;
                    }
                    .plate-progress {
                        line-height: 20px;
                        text-align: center;
                        // padding: 0 10px;
                        .slider-container {
                            width: 240px;
                            display: inline-block;
                            height: 20px;
                            background-color: #d7d7d7;
                            .progress {
                                height: 100%;
                                background-color: #02a7f0;
                                color: #fff;
                            }
                        }
                        .slider-label {
                            font-size: 16px;
                            color: #c8c8c8;
                            margin-left: 15px;
                        }
                        .slider-button {
                            margin-left: 10px;
                        }
                    }

                    .vehicle-controls {
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-evenly;
                        .el-button:nth-child(1) {
                            background-color: #02a7f0;
                            color: #fff;
                        }
                        .el-button:nth-child(2) {
                            background-color: #f59a22;
                            color: #fff;
                        }
                        .el-button:nth-child(3) {
                            background-color: #9b59b6;
                            color: #fff;
                        }
                        .el-button:nth-child(4) {
                            background-color: #fff;
                            color: #f59a22;
                        }
                        .el-button:nth-child(5) {
                            background-color: #ec808d;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .panel-bottom {
            display: flex;
            margin-top: 20px;

            .vehicle-mileage {
                flex: 1;
                text-align: center;
                .speed-chart {
                    height: 130px;
                }
            }
            .vehicle-times {
                flex: 1;
                text-align: center;
                .driver-logo {
                    margin-top: 20px;
                    height: 105px;
                }
                .driver-entry {
                    width: 18px;
                }
            }

            .data-label {
                margin-top: 10px;
            }
        }
    }
</style>
