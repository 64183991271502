<template>
    <div class="video-modules">
        <div class="video-header">
            <span class="module-title">当前选择车辆监控视频</span>
            <span class="header-button__left">
                <img @click="changeGrid(1)" src="@/assets/monitorMap/layout_one.svg" />
                <img @click="changeGrid(2)" src="@/assets/monitorMap/layout_two.svg" />
                <img @click="changeGrid(4)" src="@/assets/monitorMap/layout_four.svg" />
            </span>
            <span class="header-button__right">
                <img @click="zoomIn" src="@/assets/monitorMap/zoom_in.svg" />
                <img @click="zoomOut" src="@/assets/monitorMap/zoom_out.svg" />
            </span>
        </div>
        <div class="video-wrapper">
            <video-player :style="vStyle" v-for="video in videos" ref="video" :key="video.channelId" :params="video" class="vCommon" @talk="changeTalk"></video-player>
        </div>
        <div class="video-channels">
            <div class="channels-wrapper">
                <button :class="{ selected: selected[i] }" v-for="i in 8" :key="i" @click="pruneVideos(i)">通道{{ i }}</button>
            </div>
            <div class="channels-wrapper" v-if="isFull">
                <button :class="{ selected: selected[i + 8] }" v-for="i in 8" :key="i + 8" @click="pruneVideos(i + 8)">通道{{ i + 8 }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import VideoPlayer from "@/components/pages/admin/videoPlayer/flvPlayer";
    export default {
        inject: ["dataStore"],
        mounted() {
            this.dataStore.on("vehicleId", () => {
                this.init();
            });
        },
        methods: {
            changeGrid(number) {
                if (number === 1) {
                    this.vStyle = {
                        height: "100%",
                        width: "100%"
                    };
                } else if (number === 2) {
                    this.vStyle = {
                        height: "100%",
                        width: "50%"
                    };
                } else if (number === 4) {
                    this.vStyle = {
                        height: "50%",
                        width: "50%"
                    };
                }
            },
            reset() {
                this.videos = [];
                this.selected = {};
                this.vStyle = {
                    width: "100%",
                    height: "100%"
                };
            },
            init() {
                this.reset();
                this.$http
                    .post("/base/device/queryByVehicleId", {
                        vehicleId: this.dataStore.vehicleId
                    })
                    .then(({ detail }) => {
                        console.log(detail);
                        if (Array.isArray(detail)) {
                            const [{ clientId }] = detail;
                            this.clientId = clientId;
                        }
                    });
            },
            pruneVideos(channelId) {
                if (this.clientId) {
                    const checked = this.selected[channelId];
                    if (!checked) {
                        this.videos.push({
                            clientId: this.clientId,
                            channelId
                        });
                    } else {
                        const findex = this.videos.findIndex(v => v.channelId === channelId);
                        this.videos.splice(findex, 1);
                    }
                    this.selected[channelId] = !checked;
                }
            },
            zoomIn() {
                this.isFull = false;
                this.$emit("expanded", false);
            },
            zoomOut() {
                this.isFull = true;
                this.$emit("expanded", true);
            },
            changeTalk(uuid, openFunc) {
                if (!this.talkUUid) {
                    openFunc();
                    this.talkUUid = uuid;
                } else {
                    if (this.talkUUid === uuid) {
                        this.talkUUid = "";
                    } else {
                        this.$message.error({
                            message: "请取消正在对讲的通道",
                            duration: 3000
                        });
                    }
                }
            }
        },
        data() {
            return {
                isFull: false,

                videos: [],
                clientId: "",
                plateNum: "",
                vStyle: {
                    width: "100%",
                    height: "100%"
                },
                talkUUid: "",

                selected: {}
            };
        },
        components: {
            VideoPlayer
        }
    };
</script>
<style lang="scss" scoped>
    .video-modules {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        background-color: #fff;
        padding: 10px 10px 0 10px;
        .video-header {
            .module-title {
                float: left;
            }
            .header-button__left {
                float: left;
                margin-left: 5px;
            }
            .header-button__right {
                float: right;
            }
            img {
                width: 13px;
                margin-right: 5px;
                cursor: pointer;
            }
        }
        .video-wrapper {
            margin-top: 5px;
            flex: 1;
            border: 1px solid #dcdcdc;
            overflow-y: auto;
        }
        .video-channels {
            border-left: 1px solid #dcdcdc;
            border-bottom: 1px solid #dcdcdc;
            border-right: 1px solid #dcdcdc;
            .channels-wrapper {
                margin-left: 5px;
                margin-top: 5px;
                margin-bottom: 5px;
                button {
                    margin-right: 5px;
                    cursor: pointer;
                    border: 1px solid #4786e3;
                    background-color: #fff;
                    color: #4786e3;
                    border-radius: 3px;
                }

                .selected {
                    background-color: #4786e3;
                    color: #fff;
                }
            }
        }
    }
</style>
