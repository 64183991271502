<template>
    <div class="ra-wrapper">
        <div class="ra-nav">
            <div v-for="r in routes" :key="r.routeId" class="ra-nav-item" :class="{ 'is-active': active === r.routeId }" @click="tabClick(r.routeId)">
                <div class="tab_label_title">{{ r.cName }}发车时刻表</div>
            </div>
        </div>
        <div class="ra-content">
            <dispatch-timer :routeId="curRoute.routeId" :name="curRoute.cName" :rType="curRoute.rType"></dispatch-timer>
        </div>
    </div>
</template>

<script>
    import DispatchTimer from "./DispatchTimer.vue";
    export default {
        inject: ["dataStore"],
        mounted() {
            this.dataStore.on("routeIds", () => {
                this.$http
                    .post("/route/info/queryRouteInfoByOrgIds", {
                        routeIds: this.dataStore.routeIds.join(",")
                    })
                    .then(({ detail }) => {
                        if (Array.isArray(detail)) {
                            this.routes = detail;
                            this.active = this.routes[0].routeId;
                        }
                    });
            });
        },
        methods: {
            tabClick(id) {
                this.active = id;
            }
        },
        data() {
            return {
                active: "",
                routes: [],

                routeMap: {}
            };
        },
        computed: {
            curRoute: function() {
                const r = this.routes.find(v => v.routeId === this.active);
                return r ? r : {};
            }
        },
        components: { DispatchTimer }
    };
</script>
<style lang="scss" scoped="ra-wrapper">
    .ra-wrapper {
        background-color: white;
        display: flex;
        & > .ra-nav {
            width: 50px;
            .ra-nav-item {
                text-align: center;
                color: #c8c8c8;
                cursor: pointer;
                .tab_label_title {
                    margin: 0 auto;
                    width: 15px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 14px;
                    padding: 12px 0;
                }
            }
            & > .is-active {
                background-color: #3572f2;
                color: #fff;
            }
        }
        & > .ra-content {
            box-shadow: -4px 0 5px #ebebeb;
            width: 470px;
            padding: 10px 15px;
        }
    }
</style>
