<template>
    <div class="real_time_site" :class="{ type_down: type == 1 }">
        <div class="site_name" v-if="type == 0">{{ siteName }}</div>
        <div class="vehicles" v-if="type == 1">
            <div class="ve" v-for="(one, k) in vehicleList" :key="k">{{ one.customNo }}</div>
        </div>
        <el-popover :placement="type == 1 ? 'top' : 'bottom'" trigger="hover" :disabled="vehicleList.length <= 1">
            <ul class="pop_ul">
                <li class="pop_li" v-for="(one, kk) in vehicleList" :key="kk">
                    <div class="point">
                        <div class="mask out" :class="{ in: one.isOnline == '1' }"></div>
                    </div>
                    <span>
                        {{ one.customNo }}
                    </span>
                </li>
            </ul>
            <div class="point" slot="reference">
                {{ num }}
                <div class="mask" :class="mainMaskStyle"></div>
            </div>
        </el-popover>
        <div class="vehicles" v-if="type == 0">
            <div class="ve" v-for="(one, k) in vehicleList" :key="k">{{ one.customNo }}</div>
        </div>
        <div class="site_name" v-if="type == 1">{{ siteName }}</div>
    </div>
</template>
<script>
    import Util from "@/common/js/util.js";
    export default {
        props: {
            siteName: {
                type: String,
                default: "固戍地铁站"
            },
            num: {
                type: Number,
                default: 5
            },
            type: {
                type: String,
                default: "0"
            },
            vehicleList: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        computed: {
            mainMaskStyle: function () {
                let c = "";
                for (var i = this.vehicleList.length - 1; i >= 0; i--) {
                    if (this.vehicleList[i].isOnline == "1") {
                        c = "in";
                        break;
                    }
                    c = "out";
                }
                return c;
            }
        },
        filters: {
            vehicleNoFilter: val => {
                if (Util.isChinese(val)) {
                    return val.substr(1);
                }
                return val;
            }
        }
    };
</script>
<style lang="scss" scoped="real_time_site">
    @import "./realTime.scss";

    .real_time_site {
        width: $site_size;
    }

    .point {
        line-height: $site_size - 4px;
        text-align: center;
        width: $site_size;
        height: $site_size;
        border-radius: 50%;
        border: 2px solid $rbc;
        background: #fff;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        box-sizing: border-box;
        @extend .hand;

        .mask {
            position: absolute;
            left: -2px;
            top: -2px;
            width: $site_size;
            height: $site_size;

            &.out {
                background: scale-color($rbc, $lightness: 50%);
            }

            &.in {
                background: $rbc;
            }
        }
    }

    .site_name {
        writing-mode: vertical-lr;
        letter-spacing: 2px;
        word-break: keep-all;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 140px;
        text-align: right;
        line-height: $site_size;
        padding: 4px 0;
    }

    .vehicles {
        height: 60px;
        transform: translateX(-7px);
        .ve {
            float: left;
            white-space: nowrap;
        }
    }

    .type_down {
        .site_name {
            text-align: left;
        }

        .vehicles {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    .pop_ul {
        .pop_li {
            > * {
                vertical-align: middle;
            }

            .point {
                display: inline-block;
                width: $site_size / 1.5;
                height: $site_size / 1.5;
            }
        }
    }
</style>
