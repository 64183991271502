<template>
    <div
        class="el-slider__button-wrapper"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @mousedown="onButtonDown"
        @touchstart="onButtonDown"
        :class="{ hover: hovering, dragging: dragging }"
        :style="wrapperStyle"
        ref="button"
        tabindex="0"
        @focus="handleMouseEnter"
        @blur="handleMouseLeave"
    >
        <div @mouseup="mouseup" @mousedown="mousedown" class="el-slider__button slider__button" :class="{ hover: hovering, dragging: dragging }"></div>
    </div>
</template>

<script>
    export default {
        name: "SliderButton",

        props: {
            value: {
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                hovering: false,
                dragging: false,
                isClick: false,
                startX: 0,
                currentX: 0,
                startY: 0,
                currentY: 0,
                startPosition: 0,
                newPosition: null,
                oldValue: this.value
            };
        },

        computed: {
            max() {
                return this.$parent.max;
            },

            min() {
                return this.$parent.min;
            },

            precision() {
                return this.$parent.precision;
            },

            currentPosition() {
                return `${((this.value - this.min) / (this.max - this.min)) * 100}%`;
            },

            wrapperStyle() {
                return { left: this.currentPosition };
            }
        },

        watch: {
            dragging(val) {
                this.$parent.dragging = val;
            }
        },

        methods: {
            mouseup() {
                this.$emit("mouseup");
            },
            mousedown() {
                this.$emit("mousedown");
            },
            handleMouseEnter() {
                this.hovering = true;
            },

            handleMouseLeave() {
                this.hovering = false;
            },

            onButtonDown(event) {
                event.preventDefault();
                this.onDragStart(event);
                window.addEventListener("mousemove", this.onDragging);
                window.addEventListener("mouseup", this.onDragEnd);
                window.addEventListener("contextmenu", this.onDragEnd);
            },
            onDragStart(event) {
                this.dragging = true;
                this.isClick = true;
                this.startX = event.clientX;
                this.startPosition = parseFloat(this.currentPosition);
                this.newPosition = this.startPosition;
            },

            onDragging(event) {
                if (this.dragging) {
                    this.isClick = false;
                    this.$parent.resetSize();
                    let diff = 0;
                    this.currentX = event.clientX;
                    diff = ((this.currentX - this.startX) / this.$parent.sliderSize) * 100;
                    this.newPosition = this.startPosition + diff;

                    this.setPosition(this.newPosition);
                }
            },

            onDragEnd() {
                if (this.dragging) {
                    /*
                     * 防止在 mouseup 后立即触发 click，导致滑块有几率产生一小段位移
                     * 不使用 preventDefault 是因为 mouseup 和 click 没有注册在同一个 DOM 上
                     */
                    setTimeout(() => {
                        this.dragging = false;
                        if (!this.isClick) {
                            this.setPosition(this.newPosition);
                            this.$parent.emitChange();
                        }
                    }, 0);
                    window.removeEventListener("mousemove", this.onDragging);
                    window.removeEventListener("touchmove", this.onDragging);
                    window.removeEventListener("mouseup", this.onDragEnd);
                    window.removeEventListener("touchend", this.onDragEnd);
                    window.removeEventListener("contextmenu", this.onDragEnd);
                }
            },

            setPosition(newPosition) {
                if (newPosition === null || isNaN(newPosition)) {
                    return;
                }
                if (newPosition < 0) {
                    newPosition = 0;
                } else if (newPosition > 100) {
                    newPosition = 100;
                }
                const lengthPerStep = 100 / (this.max - this.min);
                const steps = Math.round(newPosition / lengthPerStep);
                let value = steps * lengthPerStep * (this.max - this.min) * 0.01 + this.min;
                value = parseFloat(value.toFixed(this.precision));
                this.$emit("input", value);
                if (!this.dragging && this.value !== this.oldValue) {
                    this.oldValue = this.value;
                }
            }
        }
    };
</script>
<style scoped>
    .slider__button {
        /* margin-top: 10px; */
        display: inline-block;
        vertical-align: middle;
        /* margin-left: 5px; */
    }
</style>
